import React from "react";
import { Anchor, Box, Button, Heading, Paragraph, ResponsiveContext } from "grommet";
import { FormattedMessage } from "react-intl";

export const AboutBlock: React.FC = () => {
  const size = React.useContext(ResponsiveContext);
  const txtMargin = size === "small" ? "small" : "xlarge";
  const containerProps =
    size === "small"
      ? {
          margin: { horizontal: "none" },
          pad: { horizontal: "none", vertical: "small" },
        }
      : {
          margin: { horizontal: "xlarge" },
          pad: { horizontal: "xlarge", vertical: "large" },
        };

  return (
    <>
      <Box gridArea="hunting-cta-area" align="center" justify="center">
        <Anchor style={{ margin: "25px" }} href={"/services/outsourcing"}>
          <Button
            primary
            margin={{ bottom: "small" }}
            size="large"
            label={
              <FormattedMessage id="featuredBanner.button.outsourcingbodyshop" />
            }
          />
        </Anchor>
        <Paragraph size="small" style={{ margin: "25px" }} textAlign="center">
          <FormattedMessage id="featuredBanner.text.hunting" />
          <br />
          <br />
          <br />
          <p> </p>
        </Paragraph>
      </Box>

      <Box
        id="about"
        direction="column"
        justify="center"
        align="center"
        {...containerProps}
      >
        <Box border="top" pad={{ horizontal: "xsmall", vertical: "small" }}>
          <Heading
            level={3}
            margin={{ top: "small", bottom: "none", horizontal: "none" }}
            textAlign="center"
          >
            <FormattedMessage id="about.title.blockTitle" />
          </Heading>
        </Box>

        <Paragraph textAlign="center" margin={{ horizontal: txtMargin }} fill>
          A <b>Data Marketplace</b> é uma Consultoria com especialização em{" "}
          <b>dados</b> que possui como missão disseminar a cultura “data-driven”
          e apoiar através de soluções e serviços nossos clientes em sua jornada
          de <b>transformação digital</b>.
        </Paragraph>
      </Box>
    </>
  );
};
