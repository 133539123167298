import React from "react";
import { Box, Carousel, Heading, Image } from "grommet";

import AlteryxLogo from "../../images/partners/alteryx-logo.png";
import AtaccamaLogo from "../../images/partners/ataccama-logo.png";
import AwsLogo from "../../images/partners/aws-logo.png";
import AzureLogo from "../../images/partners/azure-logo.png";
import GoogleCloudLogo from "../../images/partners/google-cloud-platform-logo.png";
import OneTrustLogo from "../../images/partners/onetrust-athena-logo.png";
import StambiaLogo from "../../images/partners/stambia-logo.png";
import TableauLogo from "../../images/partners/tableau-logo.png";
import { FormattedMessage } from "react-intl";

export const PartnerCarousel: React.FC = () => {
  return (
    <Box pad={{ horizontal: "medium", top: "large", bottom: "xlarge" }}>
      <Heading
        level={3}
        textAlign="center"
        fill
        margin={{ horizontal: "none", top: "none", bottom: "xlarge" }}
      >
        <FormattedMessage id="partnerCarousel.title.partners" />
      </Heading>
      <Carousel controls={false} play={10000}>
        <Box direction="row" justify="around">
          <Image src={AlteryxLogo} width="15%" />
          <Image src={AtaccamaLogo} width="15%" />
          <Image src={AwsLogo} width="15%" />
          <Image src={AzureLogo} width="15%" />
        </Box>
        <Box direction="row" justify="around">
          <Image src={GoogleCloudLogo} width="15%" />
          <Image src={OneTrustLogo} width="15%" />
          <Image src={StambiaLogo} width="15%" />
          <Image src={TableauLogo} width="15%" />
        </Box>
      </Carousel>
    </Box>
  );
};
