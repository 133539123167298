import * as React from "react";
import type { HeadFC } from "gatsby";
import { Layout } from "../components/layout";
import { FeaturedBanner } from "../components/featured-banner";
import { AboutBlock } from "../components/about-block";
import { ServiceBlock } from "../components/service-block";
import { PartnerCarousel } from "../components/partner-carousel";
import { PageContent } from "grommet";
import { PageHead } from "../components/page-head";

const IndexPage = () => {
  return (
    <Layout >
      <PageContent>
        <FeaturedBanner />
        <AboutBlock />
        <ServiceBlock />
      </PageContent>
      <PageContent background={{ fill: "horizontal", color: "brandPrimary" }}>
        <PartnerCarousel />
      </PageContent>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <PageHead pageTitle="Data Marketplace" />;
