import React from "react";

import {
  CloudUpload,
  FormSearch,
  Info,
  Technology,
  Code,
  UserExpert,
  Capacity,
  Integration,
} from "grommet-icons";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Grid,
  Paragraph,
  Button,
  Heading,
  Anchor,
} from "grommet";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";

export const ServiceBlock: React.FC = () => {
  return (
    <Box pad="none" margin={{ bottom: "xlarge" }}>
      <Grid
        style={{ marginBottom: "30px" }}
        gap="medium"
        columns={{ count: "fit", size: "small" }}
      >
        {/* Data */}
        <Card key={`service-card-data`} elevation="small" background="white">
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <FormSearch size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Data
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor color="#666666" href={"/services/data#Strategy"}>
                        Strategy
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/data#Governance"}
                      >
                        Governance
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/data#Compliance"}
                      >
                        Compliance
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/data#Architecture"}
                      >
                        Architecture
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/data#Engineering"}
                      >
                        Engineering
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/data#Visualization"}
                      >
                        Visualization
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/data#Analytics"}>
                        Analytics | Science
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/data" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>

        {/* Cloud */}

        <Card key={`service-card-cloud`} elevation="small" background="white">
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <CloudUpload size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Cloud
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor color="#666666" href={"/services/cloud#Strategy"}>
                        Strategy
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/cloud#Management"}
                      >
                        Management
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/cloud#Cyber Security"}
                      >
                        Cyber Security
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/cloud" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>

        {/* Technology */}
        <Card
          key={`service-card-technology`}
          elevation="small"
          background="white"
        >
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <Technology size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Technology
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor color="#666666" href={"/services/technology#BI"}>
                        BI | Self Service BI
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/technology#DataLakeBigData"}
                      >
                        Data Lake
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/technology#DataLakeBigData"}
                      >
                        Big Data
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/technology#BlockChain"}
                      >
                        Block Chain
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/technology#AI"}>
                        Artificial Intelligence
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/technology#RPA"}>
                        RPA | Bots
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/technology#IoT"}>
                        IoT
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/technology" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>

        {/* Digital */}
        <Card key={`service-card-digital`} elevation="small" background="white">
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <Info size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Digital
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/digital#Branding"}
                      >
                        Branding
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/digital#CRO"}>
                        CRO
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/digital#GrowthHacking"}
                      >
                        Growth Hacking
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/digital#SEO"}>
                        SEO
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/digital#MídiaPaga"}
                      >
                        Mídia Paga
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/digital#MarketingConteúdo"}
                      >
                        Marketing Conteúdo
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/digital#AutomaçãoMarketing"}
                      >
                        Automação Marketing
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/digital#InboundMarketing"}
                      >
                        Inbound Marketing
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/digital" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>
      </Grid>

      <Grid gap="medium" columns={{ count: "fit", size: "small" }}>
        {/* Developer */}
        <Card
          key={`service-card-developer`}
          elevation="small"
          background="white"
        >
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <Code size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Developer
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/consulting#Plan"}
                      >
                        Plan & Develop
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/consulting#Build"}
                      >
                        Build & Code Quality
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/consulting#Test"}
                      >
                        Test
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/consulting#DevSecOps"}
                      >
                        DevSecOps
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/consulting" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>

        {/* Profissionais Especializados */}
        <Card
          key={`service-card-professionals`}
          elevation="small"
          background="white"
        >
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <UserExpert size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Profissionais Especializados
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/professionals#Outsourcing"}
                      >
                        Outsourcing
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/professionals#BodyShop"}
                      >
                        Body Shop
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/professionals#Hunting"}
                      >
                        Hunting
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="/services/professionals" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>

        {/* Capacity */}
        <Card
          key={`service-card-capacity`}
          elevation="small"
          background="white"
        >
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <Capacity size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Capacity
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li style={{ marginBottom: "1%" }}>
                      <Anchor color="#666666" href={"#"}>
                        Capacitação e Orientação para Gestores
                      </Anchor>
                    </li>
                    <li style={{ marginBottom: "1%" }}>
                      <Anchor color="#666666" href={"#"}>
                        Promovendo a Cultura Data-Driven na Cultura
                        Organizacional
                      </Anchor>
                    </li>
                    <li style={{ marginBottom: "1%" }}>
                      <Anchor color="#666666" href={"#"}>
                        Gestão e Governança de Dados
                      </Anchor>
                    </li>
                    <li style={{ marginBottom: "1%" }}>
                      <Anchor color="#666666" href={"#"}>
                        Capacitação em Ciência de Dados
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/capacity" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>

        {/* Solutions */}

        <Card
          key={`service-card-solutions`}
          elevation="small"
          background="white"
        >
          <CardBody pad="small">
            <Box style={{ paddingTop: "7%" }} gap="small" align="center">
              <Integration size="large" color="#3600b2" />
              <Box>
                <Heading
                  level={3}
                  margin="none"
                  size="xsmall"
                  textAlign="center"
                  color="#3600b2"
                >
                  Solutions
                </Heading>
                <Paragraph size="small" textAlign="center">
                  <ul style={{ textAlign: "left" }}>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/solutions#TribeRH"}
                      >
                        Tribe RH
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/solutions#HeresMyCopy"}
                      >
                        Here’s My Copy
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/solutions#Inhabitant"}
                      >
                        Inhabitant
                      </Anchor>
                    </li>
                    <li>
                      <Anchor
                        color="#666666"
                        href={"/services/solutions#ResearchHub"}
                      >
                        Research Hub
                      </Anchor>
                    </li>
                    <li>
                      <Anchor color="#666666" href={"/services/solutions#DRP"}>
                        Digital Research Platform
                      </Anchor>
                    </li>
                  </ul>
                </Paragraph>
              </Box>
            </Box>
          </CardBody>
          <CardFooter pad="small" justify="center">
            <Link to="services/solutions" style={{ marginBottom: "7%" }}>
              <Button
                primary
                size="medium"
                label={<FormattedMessage id="serviceBlock.button.readMore" />}
                style={{ marginBottom: "7%" }}
              />
            </Link>
          </CardFooter>
        </Card>
      </Grid>
    </Box>
  );
};
