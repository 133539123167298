import React, { useContext } from "react";
import {
  Box,
  Grid,
  ResponsiveContext,
  Button,
  Paragraph,
  Text,
  Anchor,
} from "grommet";
import { Link } from "gatsby";

import DataGovernanceImage from "../../images/Data-Governance.png";
import DataScienceImage from "../../images/data-science.png";
import GenerativeIAImage from "../../images/Generative-AI.png";
import DataArchitectureEngineeringImage from "../../images/Data-Architecture-Engineering.png";
import CloudImage from "../../images/Cloud.png";
import DigitalImage from "../../images/Digital.png";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

// TODO: improve these definitions
const bannerGridAreas = [
  { name: "outsourcing-area", start: [0, 1], end: [0, 4] },
  { name: "habitant-area", start: [1, 0], end: [2, 4] },
  { name: "hunting-cta-area", start: [3, 1], end: [4, 1] },
  { name: "hunting-area", start: [3, 2], end: [4, 3] },
  { name: "growth-area", start: [1, 3], end: [1, 5] },
  { name: "cloud-management-area", start: [2, 3], end: [3, 4] },
  { name: "data-science-area", start: [4, 3], end: [4, 4] },
];

const columns = {
  small: ["auto"],
  medium: ["small", "auto", "small", "small", "small"],
};

const rows = {
  small: ["medium", "medium", "auto", "medium", "medium", "medium", "medium"],
  medium: ["48px", "xsmall", "small", "xsmall", "xsmall", "48px"],
};

const fixedGridAreas = {
  small: [
    { name: "outsourcing-area", start: [0, 0], end: [0, 0] },
    { name: "habitant-area", start: [0, 1], end: [0, 1] },
    { name: "hunting-cta-area", start: [0, 2], end: [0, 2] },
    { name: "hunting-area", start: [0, 3], end: [0, 3] },
    { name: "growth-area", start: [0, 4], end: [0, 4] },
    { name: "cloud-management-area", start: [0, 5], end: [0, 5] },
    { name: "data-science-area", start: [0, 6], end: [0, 6] },
  ],
  medium: bannerGridAreas,
};

export const FeaturedBanner: React.FC = () => {
  const size = useContext(ResponsiveContext);
  const { formatMessage } = useIntl();

  // TODO: improve this code
  const columnsValue = columns[size] ? columns[size] : columns.medium;
  const rowsValue = rows[size] ? rows[size] : rows.medium;
  const areasValue = fixedGridAreas[size]
    ? fixedGridAreas[size]
    : fixedGridAreas.medium;

  return (
    <Grid
      columns={columnsValue}
      rows={rowsValue}
      areas={areasValue}
      gap="small"
      fill
      pad={{ vertical: "medium" }}
    >
      <Box
        gridArea="outsourcing-area"
        align="center"
        justify="center"
        round="xsmall"
      >
        <Paragraph size="small" margin={{ bottom: "small" }} textAlign="center">
          <FormattedMessage id="featuredBanner.text.outsourcing" />
        </Paragraph>
        <BoxLinkContainer
          height="100%"
          width="100%"
          round="small"
          background={{
            image: `url(${CloudImage})`,
          }}
        >
          <LayeredLink to="/services/cloud">
            <CustomTitle
              margin="none"
              textAlign="center"
              size="large"
              color="white"
              weight="bold"
            >
              <FormattedMessage id="featuredBanner.title.cloud" />
            </CustomTitle>
          </LayeredLink>
        </BoxLinkContainer>
        <Anchor href={"/services/hunting"}>
          <Button
            primary
            size="large"
            margin={{ top: "small" }}
            label={<FormattedMessage id="featuredBanner.button.join" />}
          />
        </Anchor>
      </Box>
      <BoxLinkContainer
        gridArea="habitant-area"
        round="small"
        background={{
          image: `url(${GenerativeIAImage})`,
        }}
      >
        <LayeredLink to="/services/generative-ai">
          <CustomTitle
            margin="none"
            textAlign="center"
            size="3xl"
            color="white"
            weight="bold"
          >
            <FormattedMessage id="featuredBanner.title.generativeAI" />
          </CustomTitle>
        </LayeredLink>
      </BoxLinkContainer>
      <BoxLinkContainer
        gridArea="hunting-area"
        round="small"
        background={{
          image: `url(${DataArchitectureEngineeringImage})`,
        }}
      >
        <LayeredLink to={"/services/data#architecture"}>
          <CustomTitle
            margin="none"
            textAlign="center"
            size="xlarge"
            color="white"
            weight="bold"
          >
            <FormattedMessage id="featuredBanner.title.dataarceng" />
          </CustomTitle>
        </LayeredLink>
      </BoxLinkContainer>
      <BoxLinkContainer
        gridArea="growth-area"
        round="small"
        background={{
          image: `url(${DigitalImage})`,
        }}
      >
        <LayeredLink to="/services/digital">
          <CustomTitle
            margin="none"
            textAlign="center"
            size="xxlarge"
            color="white"
            weight="bold"
          >
            <FormattedMessage id="featuredBanner.title.growth" />
          </CustomTitle>
        </LayeredLink>
      </BoxLinkContainer>
      <BoxLinkContainer
        gridArea="cloud-management-area"
        round="small"
        background={{
          image: `url(${DataGovernanceImage})`,
        }}
      >
        <LayeredLink to="/services/data#governance">
          <CustomTitle
            margin="none"
            textAlign="center"
            size="large"
            color="white"
            weight="bold"
          >
            <FormattedMessage id="featuredBanner.title.cloudManagement" />
          </CustomTitle>
        </LayeredLink>
      </BoxLinkContainer>
      <BoxLinkContainer
        gridArea="data-science-area"
        round="small"
        background={{
          image: `url(${DataScienceImage})`,
        }}
      >
        <LayeredLink to="/services/data#science">
          <CustomTitle
            margin="none"
            textAlign="center"
            size="large"
            color="white"
            weight="bold"
          >
            <FormattedMessage id="featuredBanner.title.dataScience" />
          </CustomTitle>
        </LayeredLink>
      </BoxLinkContainer>
    </Grid>
  );
};

const CustomTitle = styled(Text)`
  position: relative;
  z-index: 1;
  text-shadow: 0px 0px 5px ${(props) => props.theme.global.colors.gray64};
`;

const BoxLinkContainer = styled(Box)`
  position: relative;
  overflow: hidden;

  &:after {
    display: block;
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.global.colors.brandPrimary};
    opacity: 0.4;
    transition: opacity 300ms;
  }

  &:hover {
    &::after {
      opacity: 0.6;
    }
  }
`;

const LayeredLink = styled(Link)`
  text-decoration: none;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  span {
    transition: transform 300ms;
  }

  &:hover {
    span {
      transform: scale(1.2);
    }
  }
`;
